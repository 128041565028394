import React, { useEffect, useRef, useState } from 'react';
import { bool, func, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';

import { Form, PrimaryButton, FieldTextInput, CustomExtendedDataField, IconArrowHead, ToolTip, IconSearch, IconCheckmark } from '../../../components';

import FieldSelectUserType from '../FieldSelectUserType';
import UserFieldDisplayName from '../UserFieldDisplayName';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';

import css from './SignupForm.module.css';

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    initialValues={{ userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes) }}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        preselectedUserType,
        userTypes,
        userFields,
        values,
        nextStep,
        verifyEmail,
        setSignUpNextStep,
      } = formRenderProps;

      const { email, fname, lname, password } = values || {};
      const [userType, setUserType] = useState(null);
      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );
      
      // Custom user fields. Since user types are not supported here,
      // only fields with no user type id limitation are selected.
      const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);

      const noUserTypes = !userType && !(userTypes?.length > 0);

      const userTypeConfig = userTypes.find(config => config.userType === userType);
      const showDefaultUserFields = userType || noUserTypes;
      const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;

      const submitDisabled = invalid || submitInProgress;

      const continueDisabled = !userType || !email || !fname || !lname || !password;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {!nextStep ? <div>
            <FieldSelectUserType
              name="userType"
              userTypes={userTypes}
              hasExistingUserType={!!preselectedUserType}
              intl={intl}
              setUserType={setUserType}
            />
            <div className={css.defaultUserFields}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />

              <FieldTextInput
                className={css.password}
                type="text"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                autoComplete="phone-number"
                label={intl.formatMessage({
                  id: 'SignupForm.phoneNumberLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.phoneNumberPlaceholder',
                })}
              />

            </div>

            <PrimaryButton className={css.continueButton} type='button' onClick={() => {
              verifyEmail(values.email);
            }} disabled={continueDisabled}>
              Continue
            </PrimaryButton>
          </div> : <div>
            <div className={css.backButton} onClick={() => {
              setSignUpNextStep(false);
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M18 10.125H7.35125L12.2425 5.23375L11 4L4 11L11 18L12.2337 16.7663L7.35125 11.875H18V10.125Z" fill="#1E2022" />
              </svg>
            </div>
            <div className={css.topicComponent}>
              <div className={css.nextStepTopic}>
                Few things to ask
              </div>
              <div className={css.nextStepDesciption}>
                Help us to understand you better
              </div>
            </div>

            <div className={css.customFields}>
              {userFieldProps.map(fieldProps => (
                <CustomExtendedDataField {...fieldProps} formId={formId} />
              ))}
            </div>

            <div style={{ margin: '30px 0px' }}>
              <ToolTip title={<FormattedMessage id='SignUpForm.infoToolTip' />}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ marginRight: '6px' }}>
                  <path d="M7.9987 14.6664C4.3187 14.6624 1.33603 11.6798 1.33203 7.99977V7.86644C1.40536 4.2031 4.42203 1.2851 8.08536 1.3331C11.75 1.38244 14.688 4.3791 14.664 8.0431C14.64 11.7078 11.6634 14.6664 7.9987 14.6664ZM7.98803 13.3331H7.9987C9.41272 13.3317 10.7683 12.7688 11.7674 11.7682C12.7665 10.7676 13.3274 9.41113 13.3267 7.99711C13.326 6.58308 12.7638 5.22721 11.7637 4.22759C10.7635 3.22798 9.40739 2.66644 7.99336 2.66644C6.57934 2.66644 5.22319 3.22798 4.22307 4.22759C3.22295 5.22721 2.66074 6.58308 2.66003 7.99711C2.65933 9.41113 3.22018 10.7676 4.2193 11.7682C5.21842 12.7688 6.57401 13.3317 7.98803 13.3331ZM8.66536 11.9998H7.33203V10.6664H8.66536V11.9998ZM8.66536 9.99977H7.33203C7.32169 9.57344 7.42566 9.15212 7.63316 8.77955C7.84067 8.40698 8.14413 8.09676 8.51203 7.8811C8.95203 7.54377 9.33203 7.2531 9.33203 6.66644C9.33203 6.31281 9.19156 5.97368 8.94151 5.72363C8.69146 5.47358 8.35232 5.3331 7.9987 5.3331C7.64508 5.3331 7.30594 5.47358 7.05589 5.72363C6.80584 5.97368 6.66536 6.31281 6.66536 6.66644H5.33203V6.60644C5.33999 5.89919 5.62857 5.22408 6.13429 4.72961C6.64002 4.23513 7.32145 3.96181 8.0287 3.96977C8.73594 3.97773 9.41106 4.26631 9.90553 4.77203C10.4 5.27775 10.6733 5.95919 10.6654 6.66644C10.6418 7.02117 10.5355 7.36545 10.3549 7.67167C10.1743 7.97789 9.92442 8.23753 9.62536 8.42977C9.35495 8.59952 9.12754 8.82962 8.96097 9.10202C8.79441 9.37442 8.69323 9.6817 8.66536 9.99977Z" fill="#26A8B0" />
                </svg>
                <FormattedMessage id="SignUpForm.info" />
              </ToolTip>

              <ToolTip title={<FormattedMessage id='SignUpForm.protectToolTip' />}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ marginRight: '6px' }}>
                  <path d="M7.9987 14.6664C4.3187 14.6624 1.33603 11.6798 1.33203 7.99977V7.86644C1.40536 4.2031 4.42203 1.2851 8.08536 1.3331C11.75 1.38244 14.688 4.3791 14.664 8.0431C14.64 11.7078 11.6634 14.6664 7.9987 14.6664ZM7.98803 13.3331H7.9987C9.41272 13.3317 10.7683 12.7688 11.7674 11.7682C12.7665 10.7676 13.3274 9.41113 13.3267 7.99711C13.326 6.58308 12.7638 5.22721 11.7637 4.22759C10.7635 3.22798 9.40739 2.66644 7.99336 2.66644C6.57934 2.66644 5.22319 3.22798 4.22307 4.22759C3.22295 5.22721 2.66074 6.58308 2.66003 7.99711C2.65933 9.41113 3.22018 10.7676 4.2193 11.7682C5.21842 12.7688 6.57401 13.3317 7.98803 13.3331ZM8.66536 11.9998H7.33203V10.6664H8.66536V11.9998ZM8.66536 9.99977H7.33203C7.32169 9.57344 7.42566 9.15212 7.63316 8.77955C7.84067 8.40698 8.14413 8.09676 8.51203 7.8811C8.95203 7.54377 9.33203 7.2531 9.33203 6.66644C9.33203 6.31281 9.19156 5.97368 8.94151 5.72363C8.69146 5.47358 8.35232 5.3331 7.9987 5.3331C7.64508 5.3331 7.30594 5.47358 7.05589 5.72363C6.80584 5.97368 6.66536 6.31281 6.66536 6.66644H5.33203V6.60644C5.33999 5.89919 5.62857 5.22408 6.13429 4.72961C6.64002 4.23513 7.32145 3.96181 8.0287 3.96977C8.73594 3.97773 9.41106 4.26631 9.90553 4.77203C10.4 5.27775 10.6733 5.95919 10.6654 6.66644C10.6418 7.02117 10.5355 7.36545 10.3549 7.67167C10.1743 7.97789 9.92442 8.23753 9.62536 8.42977C9.35495 8.59952 9.12754 8.82962 8.96097 9.10202C8.79441 9.37442 8.69323 9.6817 8.66536 9.99977Z" fill="#26A8B0" />
                </svg>
                <FormattedMessage id="SignUpForm.protect" />


              </ToolTip>
            </div>


            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </div>}



          {/* <FieldSelectUserType
            name="userType"
            userTypes={userTypes}
            hasExistingUserType={!!preselectedUserType}
            intl={intl}
            setUserType={setUserType}
          />
          {showDefaultUserFields ? (
            <div className={css.defaultUserFields}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>

              <UserFieldDisplayName
                formName="SignupForm"
                className={css.row}
                userTypeConfig={userTypeConfig}
                intl={intl}
              />

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />

              <UserFieldPhoneNumber
                formName="SignupForm"
                className={css.row}
                userTypeConfig={userTypeConfig}
                intl={intl}
              />
            </div>
          ) : null}

          {showCustomUserFields ? (
            <div className={css.customFields}>
              {userFieldProps.map(fieldProps => (
                <CustomExtendedDataField {...fieldProps} formId={formId} />
              ))}
            </div>
          ) : null}

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div> */}
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
  nextStep: false,
  verifyEmail: null,
  setSignUpNextStep: null
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  nextStep: bool,
  verifyEmail: func,
  setSignUpNextStep: func,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
